import React from 'react'

import styles from './GoogleMap.module.scss'

const GoogleMap = () => {
  const baseUrl = 'https://www.google.com/maps/embed/v1/place'
  const apiKey = process.env.GOOGLE_MAP_API_KEY
  const placeId = 'ChIJcRm2aUOQcEgRVsDPFuv1xvM'

  return (
    <iframe
      className={styles.map}
      frameBorder="0"
      height={500}
      title="AirAccounting office"
      src={`${baseUrl}?key=${apiKey}&q=place_id:${placeId}`}
      allowFullScreen
    />
  )
}

export default GoogleMap
