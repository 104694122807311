import React, { useState, useEffect } from 'react'
import isEmail from 'validator/lib/isEmail'
import Textarea from 'react-textarea-autosize'
import _ from 'lodash'
import cn from 'classnames'

import styles from './ContactForm.module.scss'
import usePublicApi from '../hooks/usePublicApi'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [nameErr, setNameErr] = useState(true)
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(true)
  const [subject, setSubject] = useState('')
  const [subjectErr, setSubjectErr] = useState(true)
  const [message, setMessage] = useState('')
  const [messageErr, setMessageErr] = useState(true)
  const [formValid, setFormValid] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const [
    { isLoading, success, error },
    { sendRequest, resetError },
  ] = usePublicApi()

  useEffect(() => {
    setNameErr(name === '')
    setEmailErr(!isEmail(email))
    setSubjectErr(subject === '')
    setMessageErr(message === '')

    if (nameErr || emailErr || messageErr) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [name, nameErr, email, emailErr, message, messageErr, subject])

  const handleSubmit = () => {
    if (formValid) {
      sendRequest({
        method: 'post',
        url: '/marketing/contact-message',
        data: { name, email, subject, message },
      })
    } else {
      setShowErrors(true)
    }
  }

  if (success) {
    return (
      <div className={styles.feedback}>
        <h4>We'll be in touch!</h4>
        <p>
          We have recevied your message and have passed it onto our team who
          will contact you shortly.
        </p>
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.feedback}>
        <h4>Oops, that's an error</h4>
        <p>
          We're sorry, we encountered an error when trying to send your message
          to our team. Please try again.
        </p>
        <button
          className="btn btn-primary"
          onClick={() => resetError()}
          type="button"
        >
          Try again
        </button>
        <small className="d-block text-gray-light mt-4">
          Error code: {_.get(error, 'response.status') || '900'}
        </small>
      </div>
    )
  }

  return (
    <div className={styles.contactForm}>
      <div className={styles.split}>
        <div
          className={cn(styles.inputRequired, {
            [styles.error]: nameErr && showErrors,
          })}
        >
          <input
            disabled={isLoading}
            id="name"
            onChange={e => setName(e.target.value)}
            placeholder="Your name"
            type="text"
            value={name}
          />
          <span className={styles.errorMsg}>Please enter your name.</span>
        </div>
        <div
          className={cn(styles.inputRequired, {
            [styles.error]: emailErr && showErrors,
          })}
        >
          <input
            disabled={isLoading}
            id="email"
            onChange={e => setEmail(e.target.value)}
            placeholder="Your email"
            type="text"
            value={email}
          />
          <span className={styles.errorMsg}>
            Please enter a valid email address.
          </span>
        </div>
      </div>
      <div
        className={cn(styles.inputRequired, {
          [styles.error]: subjectErr && showErrors,
        })}
      >
        <input
          disabled={isLoading}
          id="subject"
          onChange={e => setSubject(e.target.value)}
          placeholder="Subject"
          type="text"
          value={subject}
        />
        <span className={styles.errorMsg}>Please enter a message subject.</span>
      </div>
      <div
        className={cn(styles.inputRequired, {
          [styles.error]: messageErr && showErrors,
        })}
      >
        <Textarea
          disabled={isLoading}
          id="message"
          minRows={5}
          placeholder="Enter your message..."
          onChange={e => setMessage(e.target.value)}
          value={message}
        />
        <span className={styles.errorMsg}>Please enter your message.</span>
      </div>
      <button
        className="btn btn-primary"
        disabled={isLoading}
        onClick={handleSubmit}
        type="button"
      >
        {isLoading ? 'Sending...' : 'Send your message'}
      </button>
    </div>
  )
}

export default ContactForm
